import React from 'react';
import PropTypes from 'prop-types';
import Spinner from '../Spinner';
import "./styles.css?__remix_sideEffect__";

const LoadingOverlay = ({ inlineDisplay, opaque }) =>
<div
  className={`
      loading-overlay
      ${opaque ? 'loading-overlay--opaque' : ''}
      ${inlineDisplay ? 'loading-overlay--inline' : ''}
    `}
  data-testid="loading-overlay">

    <Spinner type="dark" />
  </div>;


LoadingOverlay.propTypes = {
  inlineDisplay: PropTypes.bool,
  opaque: PropTypes.bool
};

LoadingOverlay.defaultProps = {
  inlineDisplay: true,
  opaque: false
};

export default LoadingOverlay;